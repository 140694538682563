import React, { useState } from "react";
import Letter from "./Letter";
import Key from "./Key";
import "../style-sheets/GameWindow.css";
import GameFigure from "./GameFigure";
import Modal from "./Modal";
import Notification from "./Notification";
import figureParts from "../databases/FigureParts";

function GameWindow({ wordAndClue, resetWindow }) {
  const keysLetters = "qwertyuiopasdfghjklñzxcvbnm";
  // Palabra objetivo que el jugador debe adivinar

  
  const word = wordAndClue[0];
  const clue = wordAndClue[1];

  // Estado para almacenar las letras adivinadas por el jugador
  const [currentLetters, setLetters] = useState([]);

  const [part, setPart] = useState(0);
  const [figureState, setFigureState] = useState([]);
  const [modalState, setModaLState] = useState({
    title: "",
    text: "",
    color: "",
    status: false,
  });
  const [noitific, setNotific] = useState(["Que empiece el juego.", "black"]);

  const [gameStatus, setGameStatus] = useState(false);
  // Función para manejar la lógica cuando el jugador selecciona una letra

  if (clue !== "") {
    if (word.includes(clue)) {
      if (!currentLetters.includes(clue)) {
        setLetters([...currentLetters, clue]);
      }
    }
  }

  function chekWord(e) {
    if (!gameStatus)
      if (e !== undefined)
        if (word.includes(e)) {
          if (!currentLetters.includes(e)) {
            setLetters([...currentLetters, e]);
            setNotific(["Correcto, !Letra añadida¡", "green"]);
          } else {
          }
        } else {
          setNotific(["<-- Letra incorrecta seleccionada.", "red"]);

          setPart(part + 1);

          if (figureState.length === 6) {
            setGameStatus(true);
            setModaLState({
              title: "Juego finalizado, perdiste.",
              text: `La palabra a adivinar era: ${word}`,
              color: "red",
              status: true,
            });
          }

          setFigureState([...figureState, figureParts[part]]);
        }
  }

  const getKeyValue = (e, id) => {
    chekWord(e);
  };

  window.onkeydown = function (e) {
    e = e || window.event;
    let key = e.key;
    keysLetters
      .split("")
      .map((e) => (key.toLowerCase() === e ? chekWord(e.toLowerCase()) : 0));
    if (modalState.status && key === "Enter") resetWindow(["", ""]);
  };

  // Renderizar la palabra objetivo con los espacios para las letras adivinadas
  const palabraMostrar = word
    .split("")
    .map((letra) => (currentLetters.includes(letra) ? letra : " "))
    .join("");

  if (palabraMostrar === word) {
    setModaLState({
      title: "Felicidades, !Ganaste¡",
      text: "Bien hecho.",
      color: "green",
      status: true,
    });
    setLetters([]);

    setGameStatus(true);

    //resetWindow(['', '']);
  }

  const showSettingsWindow = () => {
    resetWindow(["", ""]);
  };

  return (
    <div
      className="game-window"
      autoFocus /*onKeyDown={() => getkeyPressValue()} */
    >
      <h1 className="window-title">Juego del ahorcado</h1>
      {modalState.status ? (
        <Modal
          title={modalState.title}
          text={modalState.text}
          color={modalState.color}
          showSettingsWindow={showSettingsWindow}
        />
      ) : (
        ""
      )}
      <div className="letters-container">
      <p className="word-title">PALABRA:</p>
      <div className="word-container">
        {!gameStatus
          ? palabraMostrar
              .split("")
              .map((e, i) => <Letter key={i} Letter={e} />)
          : word.split("").map((e, i) => <Letter key={i} Letter={e} />)}
      </div>
      </div>
      <div className="figute-keypad-container">
        <div className="figure-container">
          <GameFigure ShowParts={figureState}></GameFigure>
        </div>

        <div className="notifications-and-keypad-container">
          <div className="notifications-container">
            <Notification
              text={noitific[0]}
              classType={noitific[1]}
            ></Notification>
          </div>
          <div className="keypad-container">
            {keysLetters.split("").map((e) => (
              <Key key={e} letterKey={e} getKeyValue={getKeyValue} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GameWindow;
