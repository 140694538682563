
import '../style-sheets/SettingsWindowInfo.css'
function SettingsWindowInfo({text}){
    return(
        <div className ="sw-notification-container">
            <p className="sw-notification-text">{text}</p>
        </div>
    )

}

export default SettingsWindowInfo