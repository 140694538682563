import React from "react";
import '../style-sheets/Modal.css';

function Modal({title,text,color,showSettingsWindow}){
    return(
        <div className={`modal-container modal-container--${color}`} >
            <h1 className="modal-title">{title}</h1>
            <p className="modal-text">{text}</p>
            <button 
            className="modal-button"
            onClick={() => showSettingsWindow()}
            >Jugar de nuevo</button>
        </div>
    );
}

export default Modal