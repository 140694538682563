import React, { useState } from "react";
import SettingsWindowInfo from "./SettingsWindowInfo";
import "../style-sheets/SettingsWindow.css";
import GameFigure from "./GameFigure";
import figureParts from "../databases/FigureParts";

function SettingsWindow({ saveData }) {
  const [text, setText] = useState({ show: false, text: "" });

  const validateInfo = (e) => {
    e.preventDefault();

    const word = e.target[0].value.toLowerCase();
    const clue = e.target[1].value.toLowerCase();

    let re = new RegExp(/^[A-Za-z]+$/g);

    if (!word.match(re)) {
      setTimeout(() => {
        setText({ show: false });
      }, 4000);
      setText({
        show: true,
        text: `ERROR: No se admiten caracteres especiales, números o espacios en blanco`,
      });
    } else if (clue.length > 1) {
      setTimeout(() => {
        setText({ show: false });
      }, 4000);
      setText({
        show: true,
        text: `ERROR: Solo puede ingresar una letra como pista.`,
      });
    } else if (word.length < 4) {
      setTimeout(() => {
        setText({ show: false });
      }, 4000);
      setText({
        show: true,
        text: `ERROR: Ingrese una palabra de 4 o más caracteres.`,
      });
    } else if (word.indexOf(clue) === -1) {
      setTimeout(() => {
        setText({ show: false });
      }, 4000);
      setText({
        show: true,
        text: `ERROR: La pista ingresada no coincide con ninguna letra de la palabra: ${word}`,
      });
    } else {
      saveData([
        e.target[0].value.toLowerCase(),
        e.target[1].value.toLowerCase(),
      ]);
    }
  };

  return (
    <>
      <form className="settings-form" onSubmit={validateInfo}>
        <h1 className="window-title">Juego del ahorcado</h1>
        <label className="settings-label" htmlFor="settings-word-input">
          Palabra:
          <input
            autoFocus
            className="settings-input"
            id="settings-word-input"
            type="text"
            autoComplete="off"
            required
            placeholder="Ingrese una palabra"
          />
        </label>

        <label className="settings-label" htmlFor="settings-clue-input">
          Pista *Opcional*
          <input
            className="settings-input clue"
            id="settings-clue-input"
            autoComplete="off"
            type="text"
            placeholder="Ej: a"
          />
        </label>

        <button className="settings-button">Jugar</button>
      </form>
      {text.show ? (
        <SettingsWindowInfo text={text.text}></SettingsWindowInfo>
      ) : (
        ""
      )}
      <div className="settings-figure-container">
        <GameFigure ShowParts={figureParts}></GameFigure>
      </div>

    </>
  );
}

export default SettingsWindow;
