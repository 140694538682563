const figurePartsColor = "#2b0000";

  const figureParts = [
    {
      elipse: true,
      d: "M143.747873,90.449371 A18.170887,20.506296 0 0,1 179.087647,90.449371 A18.170887,25.506296 0 0,1 143.747873,90.449371 Z",
      sW: 0.259699,
      color: figurePartsColor
    },
    {
      d: "M159.50181,169.98273 c -5.26097,-0.39642 -9.08599,-2.80389 -11.06985,-6.96739 -1.10287,-2.31455 -1.87748,-5.19517 -2.43408,-9.05179 -0.86613,-6.00137 -0.99601,-8.96171 -0.99623,-22.70686 -2.3e-4,-14.25923 0.0534,-15.07598 1.18724,-18.07229 0.88829,-2.34747 2.66859,-3.7977 5.57855,-4.54426 1.88776,-0.48431 14.15044,-0.71511 16.86965,-0.31751 4.75737,0.69562 6.53721,2.42603 7.63907,7.42694 0.27998,1.27071 0.3597,3.96993 0.4415,14.94854 0.21246,28.51407 -0.84151,36.34571 -5.17678,38.46676 -0.89163,0.43623 -1.71906,0.61915 -3.44924,0.76249 -2.19883,0.18218 -6.53602,0.21013 -8.58983,0.0554 z",
      sW: 0.22153,
      color: figurePartsColor,
    },
    {
      d: "M122.61701,146.35618 c -0.99826,-0.46142 -1.48013,-1.12749 -1.60276,-2.21547 -0.23792,-2.11082 0.84823,-3.63139 4.32579,-6.05597 7.40217,-5.16084 13.07762,-11.83659 17.7892,-20.92456 1.96428,-3.78881 2.86046,-4.84432 4.11305,-4.84432 1.67636,0 2.49526,1.31069 2.0527,3.28547 -0.3479,1.55238 -2.51701,6.90111 -4.28255,10.56017 -2.84366,5.89344 -5.20447,9.38384 -8.94075,13.21869 -4.97525,5.1065 -10.87801,8.16697 -13.45468,6.97599 z",
      sW: 0.22153,
      color: figurePartsColor,
    },
    {
      d: "M194.90643,146.6698 c -4.08974,-1.30781 -9.54959,-5.70836 -13.36497,-10.77195 -2.89158,-3.83758 -5.59359,-8.93886 -8.20791,-15.49614 -1.84492,-4.62749 -2.03372,-5.87445 -1.05525,-6.96956 0.50243,-0.56231 0.81707,-0.71754 1.45442,-0.71754 1.29246,0 1.997,0.68731 3.28205,3.20183 4.84578,9.48197 10.32838,16.40987 16.71035,21.11547 1.31047,0.96625 2.9527,2.17685 3.64939,2.69024 1.50259,1.10724 2.32387,2.22799 2.67798,3.65444 0.2312,0.93133 0.20779,1.14919 -0.2179,2.02856 -0.80686,1.66676 -2.39568,2.07448 -4.92816,1.26465 z",
      sW: 0.22153,
      color: figurePartsColor
    },
    {
      d: "M147.28004,207.35172 c -1.34115,-1.1285 -1.64384,-2.50257 -1.22962,-5.58179 0.43223,-3.2131 0.49069,-14.052 0.14554,-26.98035 -0.37604,-14.08508 0.0575,-17.3228 2.47314,-18.46909 0.93033,-0.44147 1.18988,-0.44923 2.11393,-0.0631 1.48964,0.62241 2.8327,2.39544 4.17167,5.50719 1.63592,3.80184 1.72021,5.68672 0.47009,10.5119 -1.10105,4.24981 -1.20394,6.41449 -0.5066,10.6587 0.62904,3.82848 0.59003,6.39109 -0.1488,9.7763 -0.48965,2.24349 -0.60701,3.8164 -0.54826,7.34767 0.0673,4.04744 0.0109,4.60177 -0.56876,5.58857 -1.46065,2.48643 -4.48224,3.29444 -6.37233,1.70403 z",
      sW: 0.31329,
      color: figurePartsColor

    },
    {
      d: "M174.93061,209.12396 c 1.34115,-1.1285 1.64384,-2.50257 1.22962,-5.58179 -0.43223,-3.2131 -0.49069,-14.052 -0.14554,-26.98035 0.37604,-14.08508 -0.0575,-17.3228 -2.47314,-18.46909 -0.93033,-0.44147 -1.18988,-0.44923 -2.11393,-0.0631 -1.48964,0.62241 -2.8327,2.39544 -4.17167,5.50719 -1.63592,3.80184 -1.72021,5.68672 -0.47009,10.5119 1.10105,4.24981 1.20394,6.41449 0.5066,10.6587 -0.62904,3.82848 -0.59003,6.39109 0.1488,9.7763 0.48965,2.24349 0.60701,3.8164 0.54826,7.34767 -0.0673,4.04744 -0.0109,4.60177 0.56876,5.58857 1.46065,2.48643 4.48224,3.29444 6.37233,1.70403 z",
      sW: 0.31329,
      color: figurePartsColor
    },
    {

      d: "M154.269 103.024c1.802 1.625 3.622 3.395 5.953 4.072 1.811.527 3.794.323 5.538-.396 1.744-.72 3.257-1.933 4.487-3.363.145-.168.288-.342.47-.47.199-.14.44-.22.682-.233.244-.013.49.039.71.143.44.208.766.622.918 1.085.152.463.14.97.02 1.442-.12.472-.344.912-.607 1.323-1.312 2.045-3.546 3.31-5.796 4.229-1.34.547-2.723 1.004-4.151 1.233-1.429.228-2.907.225-4.308-.137-2.026-.522-3.784-1.755-5.482-2.976-1.046-.75-2.111-1.532-2.812-2.612-.35-.54-.601-1.15-.676-1.79-.075-.639.033-1.306.355-1.864.306-.53.814-.953 1.41-1.096.604-.145 1.25.005 1.802.292.552.286 1.025.701 1.487 1.118z",
      sW: 0.264583,
      color: "#916f6f"
    }

  ];

  export default figureParts