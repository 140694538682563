import React from "react";
import "../style-sheets/Key.css";

function Key({ letterKey, getKeyValue }) {
  return (
    <button
      className="key"
      onClick={() => {
        getKeyValue(letterKey);
      }}
    >
      {letterKey}
    </button>
  );
}

export default Key;
