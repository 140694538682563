import React from "react";
import "../style-sheets/GameFigure.css";

function GameFigure({ ShowParts }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 30 210 257"
      width="1em"
      height="1em"
    >
      <path
        d="M40.759 44.182H60.81V273.19H40.759z"
        style={{
          fill: "maroon",
          strokeWidth: 0.340253,
        }}
      />
      <path
        d="M0 270.24h117.797v26.943H0z"
        style={{
          strokeWidth: 0.293289,
          fill: "maroon",
        }}
      />
      <path
        d="M25.69 48.873h145.564v13.158H25.69z"
        style={{
          fill: "maroon",
          strokeWidth: 0.199059,
        }}
      />
      <path
        className="path-ahorcado"
        d="M158.525 62.032h5.639v21.304h-5.639z"
        style={{
          fill: "#ac9393",
          strokeWidth: 0.280633,
        }}
      />
      {ShowParts.map((e, i) => (
        <path
          key={i}
          d={e.d}
          className="path-ahorcado"
          style={{
            fill: e.color,
            strokeWidth: e.sW,
          }}
        />
      ))}
    </svg>
  );
}
export default GameFigure;
