import React from "react";
import '../style-sheets/Notification.css'

function Notification({text, classType}){
    return(
        <>
        <p className={`notification-text `+ classType  }>{text}</p>
        </>
    )

}

export default Notification