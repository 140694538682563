import './App.css';
import SettingsWindow from './components/SettingsWindow';
import GameWindow from './components/GameWindow';
import './style-sheets/AnimationsKeyFrames.css'

import { useState } from 'react';
function App() {

  const [wordAndClue, setWordAndClue] = useState(['','']);

const saveData = (e) =>{
  setWordAndClue(e)
}
  const resetWindow = (e) =>{
    setWordAndClue(e)

  }
  
  return (
    <div className="App">
      
      <div className='window-container'>     
        {
         wordAndClue[0] === '' ? <SettingsWindow saveData={saveData} />:  <GameWindow wordAndClue={wordAndClue} resetWindow={resetWindow} />
        }
      </div>

    </div>
  );
}

export default App;
